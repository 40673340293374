.dialog {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000;

  &-overlay {
    width: 100%;
    min-height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.7);
    display: grid;
    place-items: center;
    padding: 30px 20px;
  }

  &-body {
    background-color: white;
    padding: 20px;
    max-width: 1024px;
    width: 100%;
    border-radius: 4px;
  }
}
