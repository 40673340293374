.vip {
  &-info {
    width: 30px;
    height: 30px;
    border-radius: 1000px;
    border: 1px solid #ccc;
    display: grid;
    place-items: center;
    cursor: pointer;
    user-select: none;
  }
}
