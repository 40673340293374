.event-form {
  display: grid;
  gap: 20px;

  &-files {
    width: 100%;
    height: 100px;

    user-select: none;

    cursor: pointer;
  }
}
