.dropdown-menu {
  z-index: 0 !important;
  right: 4px !important;
  min-width: 6rem !important;
  top: 16px !important;
}
.swiper {
  max-width: 277px;
  height: 158px;
  position: absolute;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.fontBold{
  font-weight: 600;
}