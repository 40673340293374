.post-item {
  display: grid;
  gap: 20px;
  width: 100%;
  border: 1px solid #ccc;
  //   position: relative;

  //   &::before {
  //     content: "Post";
  //     position: absolute;
  //     top: -16px;
  //     left: 15px;
  //     background-color: white;
  //     padding-left: 5px;
  //     padding-right: 5px;
  //   }

  &-body {
    display: flex;
    gap: 20px;

    &-content {
      flex: 1;
      text-align: justify;

      display: grid;
      gap: 5px;

      // &-text {
      // }

      &-author {
        color: #aaa;
        font-size: 0.8rem;

        span {
          text-transform: capitalize;
        }
      }
    }

    &-avatar {
      width: 30px;
      height: 30px;
      border: 1px solid grey;
      border-radius: 1000px;

      display: grid;
      place-items: center;

      cursor: pointer;
      position: relative;

      span {
        user-select: none;
      }
    }

    &-menu {
      position: absolute;
      top: 50%;
      right: 110%;

      display: flex;
      flex-direction: column;
      width: 150px;
      transition: "all 0.3s";
      background-color: white;
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
      cursor: auto;
      padding: 5px;
      border-radius: 4px;

      &-item {
        display: block;
        padding: 2px 10px;
        width: 100%;
        cursor: pointer;
        transition: all 0.3s;
        user-select: none;
        background-color: transparent;
        border: none;
        text-align: left;
        color: inherit;
        border-radius: 4px;

        &:hover {
          background-color: whitesmoke;
        }
      }
    }
  }

  &-footer {
    display: flex;
    gap: 10px;

    .comment-count {
      background-color: white;
      color: black;
      padding: 5px;
      border-radius: 10px;
    }
  }
}
