.subsCont {
  border: 1px solid gray;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .subsCont {
    display: block;
  }
}

.subskeys {
  font-weight: 600;
}
