.PollOptionsCont {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.p10 {
  padding: 10px;
}
.hd {
  display: flex;
  justify-content: space-between;
}

.pb-Btn {
  display: flex;
  align-items: center;
}
.pb-pl-btn {
  height: 75px;
  background: rgb(24, 162, 184) !important;
  width: 182px;
  font-weight: 600 !important;
}
.pb-pl-btn-end {
  background: rgb(24, 162, 184) !important;
  width: 100%;
  font-weight: 600 !important;
  color: #fff !important;
  margin-bottom: 10px !important;
}
.pb-pl-btn-end-fn {
  background: #add6dd !important;
  width: 100%;
  font-weight: 600 !important;
  color: #fff !important;
  margin-bottom: 10px !important;
}
.addMOre {
  background: rgb(24, 162, 184) !important;
}
.p-Section {
  display: flex;
  justify-content: end;
}
.rm {
  background: rgb(234, 12, 12) !important;
}

.pollCard {
  padding: 20px;
  border: 1px solid #383b3f;
  border-radius: 5px;
  margin: 15px 0px;
  cursor: pointer;
}

.pollCard:hover {
  padding: 20px;
  border: 1px solid #383b3f;
  border-radius: 5px;
  margin: 15px 0px;
  cursor: pointer;
  box-shadow: 0px 1px 2px #383b3f;
}
.voteCount {
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
}

.mb15 {
  margin-bottom: 15px;
}
.pollCouter {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 30px;
}

.timerContainer {
  border: 3px solid #3bacbe;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
}
.fS20 {
  font-size: 20px;
}
.timerMr {
  margin: 0px 5px;
  width: 80px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cr-ev {
  margin-left: 10px !important;
  background: #18a2b8 !important;
  color: #ffff !important;
}
.d-end {
  display: flex;
  justify-content: end;
}
