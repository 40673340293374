@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css");
:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

/* Utilities */
.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}
.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}
.btn-small {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.3rem 0.9rem;
  font-size: 0.8rem;
  border: none;
  cursor: pointer;
  margin-right: 0.2rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}
.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn-none,
.bg-none,
.badge-none,
.alert-none,
.count-none {
  background: transparent;
  color: #333;
  border: none;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type="text"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form select,
.form textarea {
  /* display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem; */
  /* border: 1px solid #ccc; */
}

.form input[type="submit"],
button {
  font: inherit;
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}
.form .social-input i.fa-facebook {
  color: #3b5998;
}
.form .social-input i.fa-instagram {
  color: #3f729b;
}
.form .social-input i.fa-youtube {
  color: #c4302b;
}
.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  position: fixed;
  z-index: 3;
  width: 100%;
  top: 0;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--primary-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

/* Landing Page */
.landing {
  position: relative;
  background: url("./img/showcase.jpg") no-repeat center center/cover;
  height: 100vh;
}

.landing-inner {
  color: #fff;
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Profiles Page */
.profile {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  align-items: center;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
  display: grid;
  grid-template-areas:
    "top top"
    "about about"
    "exp edu"
    "github github";
  grid-gap: 1rem;
}

.profile-top {
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-top img {
  width: 250px;
}

.profile-top .icons a {
  color: #fff;
  margin: 0 0.3rem;
}

.profile-top .icons a:hover {
  color: var(--dark-color);
}

.profile-about {
  grid-area: about;
  text-align: center;
}

.profile-about .skills {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-exp {
  grid-area: exp;
}

.profile-edu {
  grid-area: edu;
}

.profile-exp h2,
.profile-edu h2 {
  margin-bottom: 1rem;
}

.profile-exp > div,
.profile-edu > div {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: #ccc 1px dotted;
}

.profile-exp > div:last-child,
.profile-edu > div:last-child {
  border: 0;
}

.profile-exp p,
.profile-edu p {
  margin: 0.5rem 0;
}

.profile-github {
  grid-area: github;
}

.profile-github .repo {
  display: flex;
}

.profile-github .repo > div:first-child {
  flex: 7;
  flex-basis: 70%;
}

.profile-github > div:last-child {
  flex: 3;
  flex-basis: 20%;
}

/* Posts Page */
.post-form .post-form-header {
  background: var(--primary-color);
  padding: 0.5rem;
}

.post {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 2rem;
  align-items: center;
}

.post > div:first-child {
  text-align: center;
}

.post img {
  width: 100px;
}

.post .comment-count {
  background: var(--light-color);
  color: var(--primary-color);
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

.post .post-date {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

/* Post summary */

.postsummary {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.postsummary-bg {
  background: #fff;
  color: #333;
  border-bottom: #ddd solid 1px;
}

.postsummary > div:first-child {
  text-align: left;
}
.postsummary > div:last-child {
  text-align: left;
}

.postsummary img {
  width: 100px;
}

.postsummary .comment-count {
  background: var(--light-color);
  color: var(--primary-color);
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

/* End Post summary */

/*-- 30-05-2022 --*/

h1.text-primary {
  font-size: 36px;
  font-weight: 800;
  color: #18a2b8 !important;
}

p.lead {
  font-size: 18px;
  font-weight: 600;
}

form.form {
  margin-bottom: 20px;
}

form.form input::placeholder {
  color: #b0b0b0;
  opacity: 1;
}

form.form input:-ms-input-placeholder {
  color: #b0b0b0;
  opacity: 1;
}

form.form input::-ms-input-placeholder {
  color: #b0b0b0;
  opacity: 1;
}
.d-flex.my-2.align-items-center span {
  font-weight: 800;
  margin-right: 15px;
}

.post-form .bg-primary h3 {
  padding: 8px 15px;
  font-size: 24px;
}

form.form.my-1 input[name="link"] {
  margin-top: 10px;
  margin-bottom: 10px;
}
.form-group .row .col-4 img {
  margin-top: 15px;
}
.events-event .col-12.col-md-4.d-flex .btn:nth-child(1) {
  background: red;
  color: #fff;
}

.events-event .col-12.col-md-4.d-flex .btn:last-child {
  background: #18a2b8;
  color: #fff;
}
.form-control.StripeElement {
  padding: 9.605px 10px;
}

/*-- 30-05-2022 --*/

/*-- Event Listing page --*/
.events-event.events-post-item > .border.my-4 {
  border-radius: 5px;
}
.icons-item {
  width: 16px;
}
.event-item-row {
  display: flex;
  flex-wrap: wrap;
  margin: 2px 0;
  align-items: baseline;
}
.event-item-r {
  width: calc(100% - 16px);
  padding-left: 10px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #383b3f;
}
.event-title {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  color: #000000;
}
.event-organized-by {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}
.address-tl {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #383b3f;
}
.address-tl span {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #25a2b7;
}
.full-address {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #383b3f;
  margin-top: 5px;
}
span.copy-address {
  margin-left: 10px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #25a2b7;
}
.drop-toggle-icon svg {
  width: 12px;
  margin-top: -3px;
}
.events-post-item .col-12.col-md-8.row.gap-2 {
  padding-left: 0;
}
.events-post-item .col-12.col-md-4 {
  padding-right: 0;
}
.events-post-item .col-12.col-md-4 img {
  border-radius: 5px;
}
.events-post-item .event-item-bottom {
  border-radius: 5px;
  padding: 0;
}
.reservation-row {
  display: flex;
  width: 100%;
  padding: 15px;
  background: #25a2b7;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
}
.reservation-left {
  width: 50%;
}
.reservation-right {
  width: 50%;
}
.reservation-title {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
.reservation-benefits {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  margin-top: 6px;
}
.reservation-benefits span {
  display: block;
}
.reservation-price {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  margin-bottom: 15px;
}
.reservation-right button.btn.m-0 {
  background: #383b3f;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  height: 38px;
}
.reservation-right input.form-control {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #565656;
}
.prepaid-row {
  padding: 15px;
}
.prepaid-title {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}
.prepaid-links a {
  background: #25a2b7;
  border-radius: 5px;
  height: 38px;
  display: inline-block;
  line-height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
}
.prepaid-links {
  margin-top: 12px;
}
.reservation-from {
  flex-wrap: wrap;
  background: transparent !important;
  border: none !important;
}
.reservation-from .reservation-title {
  width: 100%;
  margin-bottom: 10px;
  color: #383b3f;
}
.reservation-from .reservation-benefits {
  color: #383b3f;
  margin-top: -5px;
  margin-bottom: 12px;
}
input.form-control.reserve-name {
  width: 35%;
}
.reservation-from form {
  width: 100%;
}
input.form-control.reserve-email {
  width: 35%;
}
select.reserve-select.form-control {
  width: 20%;
  appearance: revert;
}
button.btn.m-0.reserve-next {
  width: 120px;
  background: #26a2b7;
  color: #fff;
}
.reservatio-pf-outer input.form-control.reserve-cr-name {
  width: 35% !important;
  display: inline-block !important;
}
.reservatio-pf-outer input.form-control.reserve-cr-email {
  width: 35%;
  display: inline-block;
  margin-right: 5px;
}
.reservatio-pf-02 {
  margin-top: 10px;
}
.reservatio-pf-outer input.form-control.reserve-cr-mmyy {
  width: 15%;
  display: inline-block;
  margin-right: 5px;
}
.reservatio-pf-outer input.form-control.reserve-cr-cvv {
  width: 15%;
  display: inline-block;
  margin-right: 5px;
}
button.btn.m-0.reserve-reserve-btn {
  height: 38px;
  display: inline-block;
  position: relative;
  top: -1px;
  background: #26a2b7;
  color: #fff;
  width: 120px;
}
.events-event-action.border.drop-toggle-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  text-align: center;
  top: 10px;
  right: 10px;
}
.events-event.events-post-item {
  position: relative;
}
/*-- Event Email Page Start  --*/
.share-icons svg {
  width: 40px;
  height: 46px !important;
}
.share-icon-box span {
  display: block;
}

.share-icon-box {
  position: absolute;
  right: 40px;
}
.share-text {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #383b3f;
}
.email_main_outer {
  width: 100%;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 40px;
  position: relative;
  margin-top: 30px;
}
.event-title {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  color: #000000;
}
.date-time-outer {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}
.address-bar {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #383b3f;
}
.event-dis {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #383b3f;
}
.email_main_outer .event-title {
  margin-bottom: 16px;
}
.email_main_outer .date-time-outer {
  margin-bottom: 24px;
}
.email_main_outer .address-bar {
  margin-bottom: 24px;
}
.event-link-btn-outer {
  margin-top: 40px;
  margin-bottom: 20px;
}
.event-link-btn {
  background: #f5f5f5;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #383b3f;
  height: 32px;
  display: inline-block;
  width: 132px;
}
.email-bottom-sec {
  width: 100%;
  border-top: 1px solid #b9b9b9;
  padding-top: 20px;
  display: flex;
  justify-content: end;
}
.email-save-box {
  display: inline-block;
  text-align: center;
}
.save-res-head {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}
button.apply-pay-btn {
  background: #25a2b7;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  height: 38px;
  width: 132px;
}
button.google-pay-btn {
  background: #25a2b7;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  height: 38px;
  width: 132px;
}
/*-- Event Email Page end --*/
/*-- User Profile page start --*/
.user_profile_main_outer {
  width: 100%;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 40px;
}
.user_profile_main_outer .event-title {
  margin-bottom: 15px;
}
.user_profile_main_outer .event-dis {
  margin-bottom: 10px;
}
.user-post-item {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
}
.post-thumbnail {
  width: 130px;
  height: 110px;
}
.post-thumbnail img {
  object-fit: fill;
  height: 100%;
}
.post-info {
  width: calc(100% - 130px);
  padding: 10px 20px;
}
.post-title {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #25a2b7;
  margin-bottom: 5px;
}
/*-- User Profile page end --*/
/*-- Web Scanner Page start --*/
.web-scanner-main-outer {
  width: 100%;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 30px;
  position: relative;
}
.dropdown-time-right {
  position: absolute;
  right: 40px;
  text-align: right;
}
.time-device {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  position: relative;
  top: -8px;
}
.valid-title svg {
  width: 40px;
}
.valid-title {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 53px;
  color: #169a00;
}
.valid-title svg path {
  fill: #169a00;
}
.web-scanner-main-outer .event-title {
  text-transform: uppercase;
  margin-bottom: 15px;
}
.web-scanner-main-outer .date-time-outer {
  text-transform: uppercase;
}
.scanner-valid-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.scanner-box-right {
  width: 52%;
  background: #f5f5f5;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  height: 308px;
  position: relative;
}
.valid-box-left {
  width: 45%;
  text-align: center;
}
.scanner-title {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
  color: #000000;
}
.user-name {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 41px;
  color: #383b3f;
  margin: 20px 0;
}
.eent-fee {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 64px;
  color: #383b3f;
}
.scanner-night {
  background: #383b3f;
}
.scanner-night .event-title {
  color: #fff;
}
.scanner-night .date-time-outer {
  color: #fff;
}
.scanner-night .time-device {
  color: #fff;
}
.scanner-night .user-name {
  color: #fff;
}
.scanner-night .eent-fee {
  color: #fff;
}
.scanner-night .dropdown-arrow svg path {
  fill: #acacac;
}
/*-- Web Scanner page end --*/
/*-- Event Statistics page start --*/
.back-btn svg {
  width: 11px;
  margin-right: 4px;
}
.back-btn {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #25a2b7;
  display: flex;
  align-items: center;
}
.back-btn svg path {
  fill: #26a2b7;
}
.page-title {
  font-family: "arial";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
  margin-top: 16px;
}
.event-statistics-outer {
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 30px;
  margin-top: 20px;
}
.booking-info-title {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  color: #383b3f;
  margin: 40px 0 20px;
}
.event-statistics-outer .date-time-outer {
  margin-top: 15px;
}
.event-statistics-items {
  display: flex;
  justify-content: space-between;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
}
.event-statistics-items-left {
  width: 50%;
  padding: 20px;
}
.event-statistics-items-right {
  width: 50%;
  padding: 20px;
  border-left: 1px solid #b9b9b9;
}
.statistics-item-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.statistics-name {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}
.statistics-number {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}
/*-- Event Statistics page end --*/
/*-- Mange Event page start --*/
.event-manage-outer {
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 40px;
  margin-top: 20px;
}
.event-detail-row-outer {
  border: 1px solid #b9b9b9;
  margin-bottom: 20px;
  margin-top: 20px;
}
.event-detail-title {
  background: #f5f5f5;
  border-bottom: 1px solid #b9b9b9;
  height: 44px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #383b3f;
  padding: 11px 10px;
}
.event-manage-outer .event-title {
  margin-bottom: 10px;
}
.event-details-btn-outer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.event-details-btn-outer a {
  background: #25a2b7;
  border-radius: 5px;
  color: #fff;
  height: 38px;
  padding: 0 25px;
  border: none;
  display: inline-block;
  line-height: 38px;
  margin: 5px;
}
.event-operation-row-outer {
  border: 1px solid #b9b9b9;
}
.event-operation-title {
  background: #f5f5f5;
  border-bottom: 1px solid #b9b9b9;
  height: 44px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #383b3f;
  padding: 11px 10px;
}
.event-operation-btn-outer {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  flex-wrap: wrap;
}
.event-operation-btn-outer a {
  color: #383b3f;
  height: 38px;
  padding: 0 20px;
  margin-right: 10px;
  background: #f5f5f5;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  display: inline-block;
  line-height: 38px;
  margin: 5px;
}
/*-- Mange Event page end --*/
/*-- Table Layout style start --*/
.my-event-outer {
  padding: 30px;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  margin-top: 20px;
}
table.my-event-table {
  width: 100%;
}
table.my-event-table th,
table.my-event-table td {
  border: 1px solid #b9b9b9;
  padding: 10px;
}
table.my-event-table th {
  background: #f5f5f5;
}
table.my-event-table th span {
  float: right;
}
.t-btn-delete {
  color: #dc3545;
  margin-left: 10px;
  font-weight: 700;
}
table.my-event-table td {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #383b3f;
}
table.my-event-table th {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #383b3f;
}
.t-btn-edit {
  font-weight: 700;
}
table.my-event-table td.title-event {
  font-weight: 700 !important;
  color: #18a2b8;
}
.filter-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}
.filter-form {
  display: flex;
  align-items: center;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  margin: 0 10px;
}
.filter-to {
  display: flex;
  align-items: center;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}
.filter-title {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  margin-right: 10px;
}
.filter-city {
  margin: 0 10px;
  width: 140px;
}
.filter-postcode {
  width: 140px;
}
.filter-form-label {
  margin-right: 5px;
}
.filter-to-label {
  margin-right: 5px;
}
table.my-event-table {
  width: 100%;
  margin: 0;
  table-layout: fixed;
}
table.my-event-table.event-payments-table-outer {
  min-width: 1200px;
}
table.my-event-table.event-listed-table-outer {
  min-width: 1036px;
}
table.my-event-table.my-event-table-outer {
  min-width: 1036px;
}
.my-event-outer {
  padding: 0;
  overflow-y: scroll;
  border: none;
  border-radius: 0;
  border-right: 1px solid #b9b9b9;
}
table.my-event-table-outer th:first-child,
table.my-event-table-outer td:first-child {
  position: absolute;
  width: 260px;
  background: #fff;
  margin-top: -0.5px;
}
table.event-listed-table-outer th:first-child,
table.event-listed-table-outer td:first-child {
  position: absolute;
  width: 208px;
  background: #fff;
  margin-top: -0.5px;
}
table.event-payments-table-outer th:first-child,
table.event-payments-table-outer td:first-child {
  position: absolute;
  width: 137px;
  background: #fff;
  margin-top: -0.5px;
}
/*-- Table Layout style end --*/
/*-- PDF Layout style start --*/
.qr-code-box img {
  width: 120px;
}
.pdf-ticket-main-outer {
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  display: flex;
}
.pdf-middle-content {
  width: calc(100% - 180px);
  position: relative;
  padding: 30px;
}
.pdf-middle-t-qr-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.pdf-left-vertical-box {
  width: 80px;
  position: relative;
  align-items: center;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  border-right: 1px solid #b9b9b9;
  border-radius: 5px 0px 0px 5px;
}
.pdf-right-vertical-box {
  width: 100px;
  position: relative;
  display: flex;
  align-items: center;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  border-left: 1px solid #b9b9b9;
  border-radius: 5px 0px 0px 5px;
}
.pdf-ticket-number {
  display: block;
  height: 100%;
}
.pdf-ticket-number span {
  display: block;
  text-align: center;
}
.pdf-left-vertical-box span {
  display: grid;
  width: 100%;
  text-align: center;
  align-items: center;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 41px;
  color: #383b3f;
}
.pdf-ticket-number01 {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  align-items: center;
  text-align: center;
  color: #383b3f;
}
.pdf-ticket-number02 {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 57px;
  align-items: center;
  text-align: center;
  color: #383b3f;
  height: 100%;
}
.pdf-ticket-number-h {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 69px;
  color: #383b3f;
}
.pdf-bottom-sec {
  text-align: center;
  margin-top: 30px;
}
.pdf-vaild-title {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #383b3f;
}
.pdf-vaild-name {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 41px;
  color: #383b3f;
  margin: 10px 0;
}
.pdf-vaild-reservation {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  text-transform: uppercase;
  color: #25a2b7;
}
.qr-code-box span {
  display: block;
  text-align: center;
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #383b3f;
}
.pdf-middle-content .date-time-outer {
  margin: 10px 0 10px;
}
/*-- PDF Layout style end --*/
/*-- Post Home page style start --*/
.post-items {
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 40px;
  margin-bottom: 40px;
}

.post-title-head {
  font-family: "arial";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #383b3f;
  margin-bottom: 20px;
}

.post-tags-img-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post-thumb-img {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}

.post-thumb-img img {
  width: 116px;
  margin: 0 5px;
  border-radius: 5px;
}

.post-tags-item {
  width: 50%;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  color: #565656;
  font-family: arial;
}

.post-info-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #b9b9b9;
  margin-top: 20px;
  padding-top: 20px;
}

.post-author-name-date {
  display: flex;
}

.post-stats-details {
  display: flex;
  align-items: center;
}

.thumb-down {
  margin: 0 40px;
}

.post-public-date {
  margin-left: 30px;
}

.author-name {
  margin-left: 10px;
}

.author-pic {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #333333;
  text-align: center;
  line-height: 24px;
}

.author-pic i {
  font-size: 15px;
  color: #fff;
}

.post-items:hover .post-title-head a {
  color: #25a2b7;
}

.post-title-head a {
  color: #383b3f;
}
/*-- Post Home page style end --*/

.single-post-details {
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 40px;
}

.post-s-thumb-img {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.post-s-thumb-img img {
  width: calc(33.33% - 20px);
  margin: 10px;
  border-radius: 5px;
}

.comment-form-outer {
  background: #f5f5f5;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 30px;
  margin-top: 30px;
}

.form-title {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 22px;
  /* identical to box height, or 105% */
  color: #000000;
}

.comment-form-outer form {
  margin-top: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.form-half {
  width: 49%;
}

.comment-form-outer form input {
  width: 100%;
  height: 38px;
  padding: 0 15px;
  margin: 10px 0;
  border: 1px solid;
  border-color: #b9b9b9;
  border-radius: 5px;
}

.comment-form-outer form input[type="submit"] {
  width: 120px;
  background: #25a2b7;
  border: none;
  border-radius: 5px;
  color: #fff;
}

.comment-form-outer form .form-full:last-child {
  text-align: right;
}

.comment-form-outer form textarea {
  width: 100%;
  height: 120px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px 15px;
  border-color: #b9b9b9;
  border-radius: 5px;
}
.single-post-details .post-info-details {
  border-top: none;
  border-bottom: 1px solid #b9b9b9;
  padding-bottom: 20px;
  margin-top: 0;
}
.post-content-area ul li {
  list-style: disc;
}

/*-- My Reservatios page style start --*/
.my-reservations-outer {
  margin-top: 20px;
}
.reservatios-item {
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 20px 30px;
}
.reserve-title-btns-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.booked-date {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}
.for-by {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin-top: 10px;
}
.reserve-btn-o a {
  display: inline-block;
  background: #25a2b7;
  border-radius: 5px;
  color: #fff;
  height: 38px;
  line-height: 38px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 5px;
}
.event-name-time {
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid #b9b9b9;

  color: #26a2b7;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
}
.event-name-time span {
  font-weight: 400;
  color: black;
  margin-left: 10px;
}
/*-- My Reservatios page style end --*/

/* Mobile Styles */
@media (max-width: 991px) {
  .filter-row {
    flex-wrap: wrap;
  }
  .filter-to,
  .filter-status,
  .filter-title,
  .filter-form,
  .filter-city,
  .filter-postcode {
    margin: 5px;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .prepaid-links a {
    height: 38px;
    line-height: 38px;
    padding: 0 8px;
    margin: 3px;
    font-size: 12px;
  }
  .WrapCol {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .WrapCol {
    flex-wrap: wrap;
  }
  h1.large.text-primary {
    font-size: 28px;
    font-weight: 800;
    color: #18a2b8 !important;
    text-align: center;
  }
  .reservation-right {
    width: 100%;
    margin-top: 20px;
  }
  .reservation-left {
    width: 100%;
  }
  .reservation-row {
    flex-wrap: wrap;
  }
  .event-item-bottom .col-12.col-md-8 {
    order: -1;
  }
  form.d-flex.flex-column.flex-md-row.gap-2 {
    display: flex !important;
    flex-direction: row !important;
  }
  .reservation-right input.form-control {
    width: calc(100% - 120px);
    display: inline-block;
    float: left;
    clear: none;
  }
  .reservation-right button.btn.m-0 {
    width: 108px;
    display: inline-block;
    float: left;
  }
  .prepaid-row {
    border: 1px solid #ccc;
    margin-top: 20px;
  }
  .events-post-item .event-item-bottom {
    border-radius: 5px;
    padding: 0;
    border: none !important;
  }
  .events-event.events-post-item {
    position: relative;
  }
  .event-title-organized {
    position: absolute;
    top: 10px;
    left: 0;
  }
  .events-post-item .col-12.col-md-4.event-image {
    padding-right: 15px;
    margin-top: 60px;
  }
  .events-post-item .col-12.col-md-8.row.gap-2 {
    padding-left: 15px;
    padding-right: 0;
  }
  .events-post-item .event-item-bottom .col-12.col-md-4 {
    padding-right: 15px;
  }
  .reservation-from {
    padding: 0;
    margin-top: 20px;
  }
  .reservation-from form {
    width: 100%;
    flex-wrap: wrap;
  }
  input.form-control.reserve-name {
    width: 100%;
  }
  input.form-control.reserve-email {
    width: 100%;
  }
  select.reserve-select.form-control {
    width: 100%;
  }
  button.btn.m-0.reserve-next {
    margin-left: auto !important;
  }
  .reservation-from {
    padding: 10px;
    margin-top: 20px;
    border: 1px solid #ccc !important;
  }
  .reservatio-pf-outer input.form-control.reserve-cr-name {
    width: 100%;
  }
  .reservatio-pf-outer input.form-control.reserve-cr-email {
    width: 100%;
    display: inline-block;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .reservatio-pf-outer input.form-control.reserve-cr-cvv {
    width: 35%;
    display: inline-block;
    margin-right: 5px;
  }
  .reservatio-pf-outer input.form-control.reserve-cr-mmyy {
    width: 35%;
    display: inline-block;
    margin-right: 5px;
  }
  button.btn.m-0.reserve-reserve-btn {
    margin-top: 10px !important;
    clear: both;
  }
  .email_main_outer {
    padding: 10px;
  }
  .share-icon-box {
    right: 10px;
    top: 10px;
  }
  .email-save-box {
    display: block;
    text-align: center;
    width: 100%;
  }
  .post-thumbnail {
    width: 100%;
    height: 220px;
  }
  .post-info {
    width: 100%;
    padding: 15px;
  }
  .web-scanner-main-outer {
    padding: 20px;
  }
  .scanner-valid-row {
    flex-wrap: wrap;
  }
  .scanner-box-right {
    width: 100%;
    margin-bottom: 30px;
    height: 224px;
  }
  .valid-box-left {
    width: 100%;
  }
  .dropdown-time-right {
    position: relative;
    text-align: initial;
    width: 100%;
    display: flex;
    justify-content: space-between;
    right: auto;
  }
  .time-device {
    top: 0;
  }
  .user-name {
    font-size: 30px;
    line-height: 36px;
    margin: 10px 0;
  }
  .valid-title {
    font-size: 36px;
    line-height: 42px;
  }
  .eent-fee {
    font-size: 36px;
    line-height: 44px;
  }
  .event-statistics-outer {
    padding: 10px;
  }
  .booking-info-title {
    font-size: 28px;
    line-height: 34px;
    margin: 20px 0 20px;
  }
  .event-statistics-items {
    flex-wrap: wrap;
  }
  .event-statistics-items-left {
    width: 100%;
    padding: 10px 10px 0;
  }
  .event-statistics-items-right {
    width: 100%;
    padding: 0 10px 10px;
    border-left: none;
  }
  .event-detail-row-outer {
    margin-top: 20px;
  }
  .event-manage-outer {
    padding: 10px;
  }
  .event-details-btn-outer {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    flex-wrap: wrap;
  }
  .event-details-btn-outer button {
    margin: 5px;
  }
  .event-operation-btn-outer {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    flex-wrap: wrap;
  }
  .event-operation-btn-outer button {
    padding: 0 15px;
    margin: 5px;
  }
  table.my-event-table.my-event-table-outer {
    min-width: 718px;
  }
  table.my-event-table-outer th:first-child,
  table.my-event-table-outer td:first-child {
    position: absolute;
    width: 180px;
    background: #fff;
  }
  .back-btn {
    margin-top: 10px;
  }
  .pdf-middle-content {
    width: calc(100% - 70px);
    position: relative;
    padding: 10px;
  }
  .pdf-left-vertical-box {
    width: 30px;
  }
  .pdf-right-vertical-box {
    width: 40px;
  }
  .pdf-ticket-number02 {
    font-size: 26px;
    line-height: 26px;
  }
  .pdf-left-vertical-box span {
    font-size: 20px;
    line-height: 26px;
  }
  .pdf-ticket-number-h {
    font-size: 30px;
    line-height: 31px;
  }
  .pdf-vaild-name {
    font-size: 21px;
    line-height: 28px;
  }
  .pdf-vaild-reservation {
    font-size: 20px;
    line-height: 26px;
  }
  .pdf-ticket-number01 {
    font-size: 14px;
    line-height: 18px;
  }
  .qr-code-box img {
    width: 90px;
  }
  .qr-code-box span {
    font-size: 9.5px;
    line-height: 10px;
  }
  .pdf-left-vertical-box {
    writing-mode: lr;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    display: block;
  }
  .pdf-ticket-main-outer {
    flex-wrap: wrap;
  }
  .pdf-right-vertical-box {
    writing-mode: lr;
    width: 100%;
    text-align: center;
    padding: 15px 0;
    display: block;
  }
  .pdf-ticket-number01 {
    font-size: 30px;
    line-height: 34px;
  }
  .pdf-middle-content {
    width: 100%;
  }
  .post-items {
    padding: 10px;
    margin-bottom: 20px;
  }
  .post-tags-img-outer {
    flex-wrap: wrap;
  }
  .post-tags-item {
    width: 100%;
  }
  .post-thumb-img {
    width: 100%;
    justify-content: flex-start;
    margin-top: 5px;
  }
  .post-info-details {
    margin-top: 10px;
    padding-top: 10px;
  }
  .post-thumb-img {
    flex-wrap: wrap;
  }
  .post-thumb-img img {
    margin: 5px;
  }
  .thumb-down {
    margin: 0 10px;
  }
  .post-public-date {
    margin-left: 10px;
  }
  .single-post-details {
    padding: 10px;
  }
  .post-s-thumb-img img {
    width: calc(33.33% - 10px);
    margin: 5px;
  }
  .comment-form-outer {
    padding: 10px;
    margin-top: 20px;
  }
  .form-half {
    width: 100%;
  }
  .form-row {
    flex-wrap: wrap;
  }
  .comment-form-outer form input {
    padding: 0 10px;
    margin: 5px 0;
  }
  .comment-form-outer form textarea {
    margin-top: 5px;
    margin-bottom: 0;
  }
  .comment-form-outer form {
    margin-top: 10px;
  }
  .reservatios-item {
    padding: 10px 10px;
  }
  .reserve-title-btns-row {
    flex-wrap: wrap;
  }
  .reserve-btn-o {
    width: 100%;
    display: block;
    margin-top: 10px;
  }
  .reserve-btn-o a {
    margin: 5px;
    min-width: 200px;
    text-align: center;
    padding: 0;
  }
  .event-name-time {
    margin-top: 10px;
    line-height: 20px;
  }
  .event-name-time span {
    margin-left: 5px;
  }
}

@media (max-width: 700px) {
  .WrapCol {
    flex-wrap: wrap;
  }
  .container {
    margin-top: 8rem;
  }
  .hide-sm {
    display: none;
  }
  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }
  .large {
    font-size: 2rem;
  }
  .lead {
    font-size: 1rem;
  }
  /* Navbar */
  .navbar {
    /* display: block; */
    text-align: center;
    padding: 5px 0 0 0;
  }
  .navbar ul {
    text-align: center;
    justify-content: center;
  }
  .navbar h1 {
    margin-bottom: 1rem;
  }
  .navbar .welcome {
    display: none;
  }
  /* Profiles Page */
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .profile ul {
    display: none;
  }
  /* Profile Page */
  .profile-top img,
  .profile img {
    width: 200px;
    margin: auto;
  }
  .profile-grid {
    grid-template-areas:
      "top"
      "about"
      "exp"
      "edu"
      "github";
  }
  .profile-about .skills {
    flex-direction: column;
  }
  .dash-buttons a {
    display: block;
    width: 100%;
    margin-bottom: 0.2rem;
  }
  .post {
    grid-template-columns: 1fr;
  }
  .post a,
  .post button {
    padding: 0.3rem 0.4rem;
  }
}

@media (max-width: 480px) {
  .WrapCol {
    flex-wrap: wrap;
  }
  .post-info-details {
    flex-wrap: wrap;
  }
  .post-stats-details {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
  .post-public-date {
    margin-left: 10px;
    float: right;
    position: absolute;
    right: 0;
  }
  .post-author-name-date {
    display: flex;
    width: 100%;
    position: relative;
  }
  .post-s-thumb-img {
    flex-wrap: wrap;
  }
  .post-s-thumb-img img {
    width: calc(100% - 10px);
    margin: 5px;
  }
}
.pointer-cursor {
  cursor: pointer;
}

.events-event-action {
  width: 24px !important;
  height: 24px !important;
}

.colorTeal {
  color: #25a2b7;
}
.EnterCode {
  padding: 7px;
  background: white;
  border-radius: 8px;
}
.dashBtn {
  column-gap: 10px;
  row-gap: 15px;
}
.dashBtnCnt {
  column-gap: 10px;
  row-gap: 15px;
  justify-content: center;
}
@media (max-width: 446px) {
  .dashText {
    display: flex;
    justify-content: center;
  }
}
.dashInput {
  background: rgb(255, 255, 255);
  width: 300px;
  margin-right: 8px;
  background-color: #931fc730;
  color: #fff;
  border-radius: 8px;
  padding: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2px solid #ddd; /* Here */
}
.joinBtn {
  background: #18a2b8 !important;
}
.jz-dash-cont {
  border: 2px solid #19a2b8;
  height: 200px;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}
.cursor-pointer {
  cursor: pointer;
}

.rec-cmp-st {
  border: 1px solid #19a2b8;
  padding: 20px;
  border-radius: 5px;
}

.activefilter {
  border: 1px solid #14a2b8;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.grayed-out {
  filter: grayscale(100%);
  pointer-events: none;
  opacity: 0.5;
  padding: 20px;
}
