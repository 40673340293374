.mEventHead {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 11px;
}
.mEventName {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  margin-bottom: 15px;
  color: #25a2b7;
}
.mEventTime {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 15px;
  color: #000000;
}
.mEventLocation {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}
.eventContainer {
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 40px;
}
.eventDetailsCont {
  border: 1px solid #b9b9b9;
  margin-bottom: 30px;
  margin-top: 30px;
}
.eventDetbar {
  min-height: 44px;
  background: #f5f5f5;
  padding: 10px;
  font-weight: 700;
  font-size: 18px;
  border-bottom: 1px solid #b9b9b9;
}
.eventDeatailsBtn {
  min-height: 70px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  row-gap: 10px;
}
.btnStyle {
  width: 100px;
  height: 38px;
  background: #25a2b7;
  border-radius: 5px;
  border-color: #25a2b7;
  color: #f5f5f5;
}
.deletebtn {
  background: #dc3545;
  border-color: #dc3545;
}
.eventoperation {
  min-height: 70px;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  row-gap: 10px;
}
.btnStyleOp {
  width: 98px;
  height: 38px;
  background: #f5f5f5;
  border-radius: 5px;
  border-color: #f5f5f5;
  margin-right: 5px;
}
.StaffContainer {
  display: flex;
  padding: 40px;
  flex-wrap: wrap;
  row-gap: 10px;
}
.staffSrch {
  /* width: 50vh; */
  padding: 10px;
  margin-right: 10px;
  /* width: 20vw; */
}
.addStaffLogi {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.addStaffBtn {
  padding: 10px;
  background: #f5f5f5;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  color: #383b3f;
}
.addStaffBtnDisabled {
  padding: 10px;
  background: #f5f5f5;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  color: #383b3f;
  /* opacity: 0.2; */
  pointer-events: none;
}
.borderCont {
  border: 1px solid #b9b9b9;
  border-radius: 5px;
}
.staffmember {
  padding: 20px;
}
.stafHeading {
  margin-left: 40px;
  font-weight: 600;
}

.send-invite{
  color: #383b3f !important;
  height: 38px !important;
  padding: 0 20px !important;
  margin-right: 10px !important;
  background: #f5f5f5 !important;
  border: 1px solid #b9b9b9 !important;
  border-radius: 5px !important;
  display: inline-block !important;
  line-height: 38px !important;
  margin: 5px 0 !important;
  text-transform: none !important;
  font-size: 16px !important;
  cursor: pointer !important;
  margin: 5px !important;
}

.send-invite-false {
  pointer-events: none !important;
  opacity: 0.2 !important;
  color: #383b3f !important;
  height: 38px !important;
  padding: 0 20px !important;
  margin-right: 10px !important;
  background: #f5f5f5 !important;
  border: 1px solid #b9b9b9 !important;
  border-radius: 5px !important;
  display: inline-block !important;
  line-height: 38px !important;
  margin: 5px 0 !important;
  text-transform: none !important;
  font-size: 16px !important;
  cursor: pointer !important;
  margin: 5px !important;
}
