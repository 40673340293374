.events {
  &-event {
    position: relative;

    &-action {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: white;

      width: 30px;
      height: 30px;
      border-radius: 1000px;
      display: grid;
      place-items: center;
      cursor: pointer;
      user-select: none;
    }

    &-menu {
      display: flex;
      flex-direction: column;
      padding: 10px;
      background-color: white;
      box-shadow: 1px 2px 10px rgba($color: #000000, $alpha: 0.3);
      border-radius: 4px;
      min-width: 150px;

      &-item {
        padding: 5px 10px;
        border-radius: 4px;
        transition: 0.3s;

        &:hover {
          background-color: #ddd;
          cursor: pointer;
        }
      }
    }
  }
}
